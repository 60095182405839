import { render, staticRenderFns } from "./jhi-item-count.vue?vue&type=template&id=06cea784&"
import script from "./jhi-item-count.component.ts?vue&type=script&lang=ts&"
export * from "./jhi-item-count.component.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/hangar-gym/hangar-gym/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('06cea784')) {
      api.createRecord('06cea784', component.options)
    } else {
      api.reload('06cea784', component.options)
    }
    module.hot.accept("./jhi-item-count.vue?vue&type=template&id=06cea784&", function () {
      api.rerender('06cea784', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/webapp/app/shared/jhi-item-count.vue"
export default component.exports