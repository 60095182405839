import {Authority} from '@/shared/security/authority';

const HActivity = () => import('@/entities/h-activity/h-activity.vue');
const HActivityUpdate = () => import('@/entities/h-activity/h-activity-update.vue');
const HActivityDetails = () => import('@/entities/h-activity/h-activity-details.vue');
const HActivityGroup = () => import('@/entities/h-activity-group/h-activity-group.vue');
const HActivityGroupUpdate = () => import('@/entities/h-activity-group/h-activity-group-update.vue');
const HActivityGroupDetails = () => import('@/entities/h-activity-group/h-activity-group-details.vue');
const HAthletes = () => import('@/entities/h-athletes/h-athletes.vue');
const HAthletesUpdate = () => import('@/entities/h-athletes/h-athletes-update.vue');
const HAthletesView = () => import('@/entities/h-athletes/h-athletes-details.vue');
const HSesion = () => import('@/entities/h-sesion/h-sesion.vue');
const HSesionUpdate = () => import('@/entities/h-sesion/h-sesion-update.vue');
const HSesionUpdateUser = () => import('@/entities/h-sesion/h-sesion-update-user.vue');
const MySesions = () => import('@/entities/mysesions/mysesion.vue');
const HArm = () => import('@/entities/h-arm/h-arm.vue');
const HArmUpdate = () => import('@/entities/h-arm/h-arm-update.vue');
const HMeasures = () => import('@/entities/h-measures/h-measures.vue');
const HMeasuresUpdate = () => import('@/entities/h-measures/h-measures-update.vue');


/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default [
  {
    path: '/',
    component: Entities,
    children: [
      // activities
      {
        path: '/entities/activities',
        name: 'HActivity',
        component: HActivity,
        meta: { authorities: [Authority.ADMIN] }
      },
      {
        path: '/entities/activities/create',
        name: 'HActivityCreate',
        component: HActivityUpdate,
        meta: { authorities: [Authority.ADMIN] }
      },
      {
        path: '/entities/activities/:hActivityId',
        name: 'HActivityView',
        component: HActivityDetails,
        meta: { authorities: [Authority.ADMIN, Authority.USER] }
      },
      {
        path: '/entities/activities/edit/:hActivityGroupId',
        name: 'HActivityEdit',
        component: HActivityUpdate,
        meta: { authorities: [Authority.ADMIN] }
      },
      // Activities-group
      {
        path: '/entities/activities-group',
        name: 'HActivityGroup',
        component: HActivityGroup,
        meta: { authorities: [Authority.ADMIN] }
      },
      {
        path: '/entities/activities-group/:hActivityGroupId',
        name: 'HActivityGroupView',
        component: HActivityGroupDetails,
        meta: { authorities: [Authority.ADMIN] }
      },
      {
        path: '/entities/activities-group/create',
        name: 'HActivityGroupCreate',
        component: HActivityGroupUpdate,
        meta: { authorities: [Authority.ADMIN] }
      },
      {
        path: '/entities/activities-group/:hActivityGroupId/update',
        name: 'HActivityGroupEdit',
        component: HActivityGroupUpdate,
        meta: { authorities: [Authority.ADMIN] }
      },
      // Athletes
      {
        path: '/entities/athletes',
        name: 'HAthletes',
        component: HAthletes,
        meta: { authorities: [Authority.ADMIN] }
      },
      {
        path: '/entities/athletes/create',
        name: 'HAthletesCreate',
        component: HAthletesUpdate,
        meta: { authorities: [Authority.ADMIN] }
      },
      {
        path: '/entities/athletes/:hAthletesId/edit',
        name: 'HAthletesEdit',
        component: HAthletesUpdate,
        meta: { authorities: [Authority.ADMIN] }
      },
      {
        path: '/entities/athletes/:hAthletesId',
        name: 'HAthletesView',
        component: HAthletesView,
        meta: { authorities: [Authority.ADMIN] }
      },
      // Sesion
      {
        path: '/entities/sesion/:hAthletesId/list',
        name: 'HSesion',
        component: HSesion,
        meta: { authorities: [Authority.ADMIN] }
      },
      {
        path: '/entities/sesion/:hAthletesId/create',
        name: 'HSesionCreate',
        component: HSesionUpdate,
        meta: { authorities: [Authority.ADMIN] }
      },
      {
        path: '/entities/sesion/:hAthletesId/:hSesionId/edit',
        name: 'HSesionEdit',
        component: HSesionUpdate,
        meta: { authorities: [Authority.ADMIN] }
      },
      {
        path: '/entities/sesion/:hAthletesId/:hSesionId/edituser',
        name: 'HSesionEditUser',
        component: HSesionUpdateUser,
        meta: { authorities: [Authority.ADMIN, Authority.USER] }
      },
      {
        path: '/me',
        name: 'MySesions',
        component: MySesions,
        meta: {}
      },
      // arm
      {
        path: '/entities/athletes/:hAthletesId/arm',
        name: 'HArm',
        component: HArm,
        meta: { authorities: [Authority.ADMIN, Authority.USER] }
      },
      {
        path: '/entities/athletes/:hAthletesId/arm/create',
        name: 'HArmCreate',
        component: HArmUpdate,
        meta: { authorities: [Authority.ADMIN, Authority.USER] }
      },
      {
        path: '/entities/athletes/:hAthletesId/arm/:HArmId/edit',
        name: 'HArmEdit',
        component: HArmUpdate,
        meta: { authorities: [Authority.ADMIN] }
      },
      // measures
      {
        path: '/entities/athletes/:hAthletesId/measures',
        name: 'HMeasures',
        component: HMeasures,
        meta: { authorities: [Authority.ADMIN] }
      },
      {
        path: '/entities/athletes/:hAthletesId/measures/create',
        name: 'HMeasuresCreate',
        component: HMeasuresUpdate,
        meta: { authorities: [Authority.ADMIN] }
      },
      {
        path: '/entities/athletes/:hAthletesId/measures/:HArmId/edit',
        name: 'HMeasuresEdit',
        component: HMeasuresUpdate,
        meta: { authorities: [Authority.ADMIN] }
      },
    ],
  },
];
