export const buildPaginationQueryOpts = (paginationQuery) => {
  if (paginationQuery) {
    let sorts = '';
    for (const idx of Object.keys(paginationQuery.sort)) {
      if (sorts.length > 0) {
        sorts += '&';
      }
      sorts += 'sort=' + paginationQuery.sort[idx];
    }
    return `${sorts}&page=${paginationQuery.page}&size=${paginationQuery.size}`;
  }
  return '';
}

export const buildCriteriaQueryOpts = (criteriaQuery) => {
  let result  = '';
  if (criteriaQuery) {
    for (const criteria of Object.keys(criteriaQuery)) {
      if (criteriaQuery[criteria]) {
        result += `${criteria}=${criteriaQuery[criteria]}&`
      }
    }
  }
  return result;
}

export default buildPaginationQueryOpts;
