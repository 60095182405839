var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "footer text-center", attrs: { id: "footer" } },
    [
      _c("p", { domProps: { textContent: _vm._s(_vm.$t("footer")) } }, [
        _vm._v("Hangar Gym"),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }